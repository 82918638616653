:root {
    --pink: #ea2a5e;
    --ungu: #6a106b;
    --biruMuda: #0bb4c0;
    --biruTua: #3a108c;
}

.swiper-pagination-bullet {
    background-color: var(--pink) !important;
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--pink) !important;
}
